<template>
    <fragment>
        <a :title="$t('button.title.addItem')"
           v-if="$global.hasPermission('driversstore') && allowCreate > 0"
           v-b-tooltip.hover
           @click="handleAddDriverClick"
           class="mr-1">
            <i class="fe fe-plus"></i>
        </a>
        <a :title="$t('button.title.editItem')"
           v-if="$global.hasPermission('driversupdate') && hasId && allowUpdate > 0"
           @click="handleEditDriverClick"
           v-b-tooltip.hover>
            <i class="fe fe-edit"></i>
        </a>
        <b-modal v-model="visibility" size="lg" :title="operationTitle" hide-footer>
            <div class="driver-operation">
                <form @submit.prevent="handleSubmit" autocomplete="off">
                    <b-row>
                        <b-col cols="12">
                            <div v-show="editLoading">
                                <b-skeleton-table
                                    :rows="5"
                                    :columns="2"
                                    :table-props="{ bordered: true, striped: true }"
                                ></b-skeleton-table>
                            </div>

                            <div v-show="!editLoading">
                                <b-row>
                                    <b-col cols="12">
                                        <b-row>
                                            <b-col sm="6">
                                                <b-form-group
                                                    :label="$t('input.name')+' *'"
                                                    label-for="name"
                                                    :invalid-feedback="formErrors.first('name')"
                                                >
                                                    <b-form-input
                                                        id="name"
                                                        v-model="formFields.name"
                                                        type="text"
                                                        :state="((formErrors.has('name') ? false : null))"
                                                        ref="name"
                                                        @focus="$event.target.select()"
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col><!--/b-col-->
                                            <b-col sm="6">
                                                <b-form-group
                                                    :label="$t('input.passportNumber')"
                                                    label-for="passport_number"
                                                    :invalid-feedback="formErrors.first('passport_number')"
                                                >
                                                    <b-form-input
                                                        id="passport_number"
                                                        v-model="formFields.passport_number"
                                                        type="text"
                                                        :state="((formErrors.has('passport_number') ? false : null))"
                                                        @focus="$event.target.select()"
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col><!--/b-col-->
                                            <b-col sm="6">
                                                <b-form-group
                                                    :label="$t('input.phone')"
                                                    label-for="phone"
                                                    :invalid-feedback="formErrors.first('phone')">
                                                    <b-form-input
                                                        id="phone"
                                                        v-model="formFields.phone"
                                                        type="number"
                                                        pattern="^[0-9-+()]*"
                                                        title="+(XXX) XXX"
                                                        :state="((formErrors.has('phone') ? false : null))"
                                                        @focus="$event.target.select()"></b-form-input>
                                                </b-form-group>
                                            </b-col><!--/b-col-->
                                            <b-col sm="6">
                                                <b-form-group
                                                    :label="$t('input.email')"
                                                    label-for="email"
                                                    :invalid-feedback="formErrors.first('email')"
                                                >
                                                    <b-form-input
                                                        id="email"
                                                        v-model="formFields.email"
                                                        type="email"
                                                        :state="((formErrors.has('email') ? false : null))"
                                                        ref="email"
                                                        @focus="$event.target.select()"
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col><!--/b-col-->
                                            <b-col sm="6">
                                                <b-form-group
                                                    :label="$t('input.password')+  (((!this.hasId) && formFields.email) ? ' *' : '')"
                                                    label-for="password"
                                                    :invalid-feedback="formErrors.first('password')"
                                                >
                                                    <b-form-input
                                                        id="password"
                                                        v-model="formFields.password"
                                                        type="password"
                                                        :state="((formErrors.has('password') ? false : null))"
                                                        ref="password"
                                                        @focus="$event.target.select()"
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col><!--/b-col-->
                                        </b-row><!--/b-row-->
                                    </b-col><!--/b-col-->
                                </b-row><!--/b-row-->
                            </div>
                            <div class="quick-modal-footer pt-1">
                                <b-button
                                    size="sm"
                                    type="submit"
                                    variant="primary"
                                    :disabled="global.pendingRequests > 0"
                                    v-b-tooltip.hover :title="$t('button.title.save')"
                                >
                                    <clip-loader style="display: inline" :loading="true" color="#fff"
                                                 size="12px"
                                                 v-if="global.pendingRequests > 0"></clip-loader>
                                    <i class="fa fa-save mr-1"></i>
                                    {{$t('button.save')}}
                                </b-button>
                                <b-button variant="warning" class="ml-3"
                                          size="sm" @click="handleOperationClose"
                                          v-b-tooltip.hover :title="$t('button.title.cancel')">
                                    <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                                </b-button>
                            </div><!--/.quick-modal-footer-->
                        </b-col><!--/b-col-->
                    </b-row><!--/b-col-->
                </form>
            </div><!-- /.driver-operation -->
        </b-modal>
    </fragment>
</template>
<script>
    import {Fragment} from 'vue-fragment'
    import Error from "./../../util/Error"
    import {request} from "../../util/Request"
    import {mapState} from "vuex"
    import Treeselect from '@riophae/vue-treeselect'

    const FORM_STATE = {
        supplier_id: null,
        name: null,
        email: null,
        phone: null,
        password: null,
        is_active: true,
        driver_documents: [],
        passport_number: null,
        _method: 'post',
    };

    export default {
        props: ['allowCreate', 'allowUpdate', 'id', 'afterCreate', 'afterUpdate', 'afterCancel', 'supplier'],
        components: {
            Fragment,
            Treeselect
        },
        data() {
            return {
                formErrors: new Error({}),
                formFields: {...FORM_STATE},
                visibility: false,
                editLoading: false,
                operationTitle: this.$t('title.addDriver'),
            }
        },
        mounted() {

        },
        methods: {
            async handleSubmit() {
                this.formErrors = new Error({})
                try {
                    if(this.supplier) {
                        this.formFields.supplier_id = this.supplier
                    }

                    const response = await request({
                        url: this.formFields.id ? 'drivers/update' : 'drivers/create',
                        method: 'post',
                        data: this.formFields,
                    })

                    if (this.formFields.id) {
                        this.handleAfterDriverUpdate(this.formFields, this.formFields.id);
                    } else {
                        this.handleAfterDriverCreate(response.data);
                    }

                    this.handleOperationClose()
                } catch (error) {
                    if (error.request && error.request.status && error.request.status === 422) {
                        this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                        return false
                    }

                    this.handleServerError(error)
                }
            },
            async handleEditClick(id) {
                try {
                    this.editLoading = true
                    const response = await request({
                        method: 'get',
                        url: `/drivers/detail/${id}`,
                    })
                    this.operationTitle = this.$t('title.editDriver')
                    const {data} = response

                    const {supplier} = data;
                    delete data.supplier;

                    this.formFields = {
                        ...data,
                        is_active: (data.is_active > 0),
                        supplier_id: (supplier ? supplier.id : null),
                        password: null
                    }
                } catch (e) {
                    this.itemEditFails()
                    this.formFields = {...FORM_STATE}
                } finally {
                    this.editLoading = false
                }
            },
            handleAddDriverClick() {
                this.operationTitle = this.$t('title.addDriver');
                this.visibility = true
            },
            handleEditDriverClick() {
                this.operationTitle = this.$t('title.editDriver');
                this.visibility = true
                this.handleEditClick(this.id)
            },
            handleOperationClose() {
                this.formFields = {...FORM_STATE}
                this.visibility = false
                this.handleAfterOperationCancel()
            },
            handleAfterOperationCancel() {
                if (this.afterCancel) {
                    this.afterCancel()
                }
            },
            handleAfterDriverCreate(inputs) {
                if (this.afterCreate) {
                    this.afterCreate(inputs)
                }
            },
            handleAfterDriverUpdate(inputs) {
                if (this.afterUpdate) {
                    this.afterUpdate(inputs)
                }
            },
        },
        computed: {
            ...mapState([
                'global',
                'settings'
            ]),
            hasId() {
                return (this.id)
            },
        }
    }
</script>
