<template>
    <fragment>
        <a :title="$t('button.title.addItem')"
           v-if="$global.hasPermission('transportvehiclesstore') && allowCreate > 0"
           v-b-tooltip.hover
           @click="handleAddTransportVehicleClick"
           class="mr-1">
            <i class="fe fe-plus"></i>
        </a>
        <a :title="$t('button.title.editItem')"
           v-if="$global.hasPermission('transportvehiclesupdate') && hasId && allowUpdate > 0"
           @click="handleEditTransportVehicleClick"
           v-b-tooltip.hover>
            <i class="fe fe-edit"></i>
        </a>
        <b-modal v-model="visibility" size="lg" :title="operationTitle" hide-footer>
            <div class="transport-vehicle-operation">
                <form @submit.prevent="handleSubmit" autocomplete="off">
                    <b-row>
                        <b-col cols="12">
                            <div v-show="editLoading">
                                <b-skeleton-table
                                    :rows="5"
                                    :columns="2"
                                    :table-props="{ bordered: true, striped: true }"
                                ></b-skeleton-table>
                            </div>

                            <div v-show="!editLoading">
                                <b-row>
                                    <b-col cols="12">
                                        <b-row>
                                            <b-col sm="4">
                                                <b-form-group
                                                    :label="$t('input.meansOfTransport')+' *'"
                                                    label-for="type"
                                                    :invalid-feedback="formErrors.first('type')">
                                                    <treeselect
                                                        :multiple="false"
                                                        :options="dropdowns.transportVehiclesTypes"
                                                        placeholder=""
                                                        v-model="formFields.type"
                                                        :class="[{'invalid is-invalid': (formErrors.has('type'))}]"
                                                    />
                                                </b-form-group>
                                            </b-col><!--/b-col-->
                                            <b-col sm="4">
                                                <b-form-group
                                                    :label="$t('input.brand')+' *'"
                                                    label-for="brand"
                                                    :invalid-feedback="formErrors.first('brand')"
                                                >
                                                    <b-form-input
                                                        id="brand"
                                                        v-model="formFields.brand"
                                                        type="text"
                                                        :state="((formErrors.has('brand') ? false : null))"
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col><!--/b-col-->
                                            <b-col sm="4">
                                                <b-form-group
                                                    :label="$t('input.truckPlateNumber')+' *'"
                                                    label-for="truck_plate_number"
                                                    :invalid-feedback="formErrors.first('truck_plate_number')"
                                                >
                                                    <b-form-input
                                                        id="truck_plate_number"
                                                        v-model="formFields.truck_plate_number"
                                                        type="text"
                                                        :state="((formErrors.has('truck_plate_number') ? false : null))"
                                                        ref="truck_plate_number"
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col><!--/b-col-->
                                            <b-col sm="3">
                                                <b-form-group
                                                    :label="$t('input.trailerPlateNumber')+' *'"
                                                    label-for="trailer_plate_number"
                                                    :invalid-feedback="formErrors.first('trailer_plate_number')"
                                                >
                                                    <b-form-input
                                                        id="trailer_plate_number"
                                                        v-model="formFields.trailer_plate_number"
                                                        type="text"
                                                        :state="((formErrors.has('trailer_plate_number') ? false : null))"
                                                        ref="trailer_plate_number"
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col><!--/b-col-->
                                            <b-col sm="3">
                                                <b-form-group
                                                    :label="$t('input.capacity')"
                                                    label-for="capacity"
                                                    :invalid-feedback="formErrors.first('capacity')"
                                                >
                                                    <b-form-input
                                                        id="capacity"
                                                        v-model="formFields.capacity"
                                                        type="text"
                                                        :state="((formErrors.has('capacity') ? false : null))"
                                                        ref="capacity"
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col><!--/b-col-->
                                            <b-col sm="3">
                                                <b-form-group
                                                    :label="$t('input.euroNorm')+' *'"
                                                    label-for="euro_norm"
                                                    :invalid-feedback="formErrors.first('euro_norm')"
                                                >
                                                    <b-form-input
                                                        id="euro_norm"
                                                        v-model="formFields.euro_norm"
                                                        type="text"
                                                        :state="((formErrors.has('euro_norm') ? false : null))"
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col><!--/b-col-->
                                            <b-col sm="3">
                                                <b-form-group
                                                    :label="$t('input.yearOfProduction')+' *'"
                                                    label-for="year_of_production"
                                                    :invalid-feedback="formErrors.first('year_of_production')"
                                                >
                                                    <b-form-input
                                                        id="year_of_production"
                                                        v-model="formFields.year_of_production"
                                                        type="text"
                                                        :state="((formErrors.has('year_of_production') ? false : null))"
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col><!--/b-col-->
                                            <b-col sm="3" class="mb-5">
                                                <b-form-group
                                                    :label="$t('input.subContractor') + ':'"
                                                    label-for="sub_contractor"
                                                    :invalid-feedback="formErrors.first('sub_contractor')"
                                                >
                                                    <b-form-checkbox v-model="formFields.sub_contractor"
                                                                     name="sub_contractor">
                                                        {{$t('input.subContractor')}}
                                                    </b-form-checkbox>
                                                </b-form-group>
                                            </b-col><!--/b-col-->
                                        </b-row><!--/b-row-->
                                    </b-col><!--/b-col-->
                                </b-row><!--/b-row-->
                            </div>

                            <div class="quick-modal-footer pt-1">
                                <b-button
                                    size="sm"
                                    type="submit"
                                    variant="primary"
                                    :disabled="global.pendingRequests > 0"
                                    v-b-tooltip.hover :title="$t('button.title.save')"
                                >
                                    <clip-loader style="display: inline" :loading="true" color="#fff"
                                                 size="12px"
                                                 v-if="global.pendingRequests > 0"></clip-loader>
                                    <i class="fa fa-save mr-1"></i>
                                    {{$t('button.save')}}
                                </b-button>
                                <b-button variant="warning" class="ml-3"
                                          size="sm" @click="handleOperationClose"
                                          v-b-tooltip.hover :title="$t('button.title.cancel')">
                                    <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                                </b-button>
                            </div><!--/.quick-modal-footer-->
                        </b-col><!--/b-col-->
                    </b-row><!--/b-col-->
                </form>
            </div><!-- /.transport-vehicle-operation -->
        </b-modal>
    </fragment>
</template>
<script>
import { Fragment } from 'vue-fragment'
import Error from "./../../util/Error"
import {request} from "../../util/Request"
import {mapState} from "vuex"
import Treeselect from '@riophae/vue-treeselect'

const FORM_STATE = {
    supplier_id: null,
    capacity: null,
    type: null,
    truck_plate_number: null,
    trailer_plate_number: null,
    year_of_production: null,
    brand: null,
    euro_norm: null,
    sub_contractor:false,
    _method: 'post',
};

export default {
    props: ['allowCreate', 'allowUpdate', 'id', 'afterCreate', 'afterUpdate', 'afterCancel', 'supplier'],
    components: {
        Fragment,
        Treeselect
    },
    data() {
        return {
            formErrors: new Error({}),
            formFields: {...FORM_STATE},
            visibility: false,
            editLoading: false,
            operationTitle: this.$t('title.addTransportVehicles'),
            dropdowns: {
                transportVehiclesTypes: [],
            }
        }
    },
    mounted() {
        this.getTransportVehiclesTypes();
    },
    methods: {
        async handleSubmit() {
            this.formErrors = new Error({})
            try {
                if(this.supplier) {
                    this.formFields.supplier_id = this.supplier
                }

                const response = await request({
                    url: this.formFields.id ? 'transport/vehicles/update' : 'transport/vehicles/create',
                    method: 'post',
                    data: this.formFields,
                })

                if (this.formFields.id) {
                    this.handleAfterTransportVehicleUpdate(this.formFields, this.formFields.id);
                } else {
                    this.handleAfterTransportVehicleCreate(response.data);
                }

                this.handleOperationClose()
            } catch (error) {
                if (error.request && error.request.status && error.request.status === 422) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                    return false
                }

                this.handleServerError(error)
            }
        },
        async handleEditClick(id) {
            try {
                this.editLoading = true
                const response = await request({
                    method: 'get',
                    url: `/transport/vehicles/detail/${id}`,
                })
                this.operationTitle = this.$t('title.editTransportVehicles')
                const {data} = response

                const {supplier} = data
                delete data.supplier
                this.formFields = {
                    ...data,
                    supplier_id: (supplier ? supplier.id : null),
                    sub_contractor: ((data.sub_contractor) ? true : false)
                }
            } catch (e) {
                this.itemEditFails()
                this.formFields = {...FORM_STATE}
            } finally {
                this.editLoading = false
            }
        },
        async getTransportVehiclesTypes() {
            try {
                const response = await request({
                    url: '/dropdowns/transport/vehicles/types',
                    method: "post"
                })

                const {data} = response
                this.dropdowns.transportVehiclesTypes = data

            } catch (e) {
                this.dropdowns.transportVehiclesTypes = []
            }
        },
        handleAddTransportVehicleClick() {
            this.operationTitle = this.$t('title.addTransportVehicles');
            this.visibility = true
        },
        handleEditTransportVehicleClick() {
            this.operationTitle = this.$t('title.editTransportVehicles');
            this.visibility = true
            this.handleEditClick(this.id)
        },
        handleOperationClose() {
            this.formFields = {...FORM_STATE}
            this.visibility = false
            this.handleAfterOperationCancel()
        },
        handleAfterOperationCancel() {
            if (this.afterCancel) {
                this.afterCancel()
            }
        },
        handleAfterTransportVehicleCreate(inputs) {
            if (this.afterCreate) {
                this.afterCreate(inputs)
            }
        },
        handleAfterTransportVehicleUpdate(inputs) {
            if (this.afterUpdate) {
                this.afterUpdate(inputs)
            }
        },
    },
    computed: {
        ...mapState([
            'global',
            'settings'
        ]),
        hasId() {
            return (this.id)
        },
    }
}
</script>
